<template>
  <v-card>
    <v-card-title>Change Password</v-card-title>
    <v-card-text>
      <v-form ref="form" lazy-validation>
        <v-text-field
          v-model="profile.currentPassword"
          label="Current Password"
          v-validate="'required|min:8'"
          required
          data-vv-name="password"
          :error-messages="errors.collect('password')"
          :type="showOldPassword ? 'text' : 'password'"
          :append-icon="showOldPassword ? 'visibility' : 'visibility_off'"
          @click:append="showOldPassword = !showOldPassword"
        ></v-text-field>

        <v-text-field
          v-model="profile.newPassword"
          label="New Password"
          v-validate="'required|min:8'"
          required
          data-vv-name="New Password"
          :error-messages="errors.collect('New Password')"
          ref="newPassword"
          :type="showNewPassword ? 'text' : 'password'"
          :append-icon="showNewPassword ? 'visibility' : 'visibility_off'"
          @click:append="showNewPassword = !showNewPassword"
        ></v-text-field>

        <v-text-field
          v-model="profile.confirmNewPassword"
          label="Confirm New Password"
          v-validate="'required|min:8|confirmed:newPassword'"
          required
          data-vv-name="Confirm New Password"
          :error-messages="errors.collect('Confirm New Password')"
          ref="confirmPassword"
          :type="showConfirmPassword ? 'text' : 'password'"
          :append-icon="showConfirmPassword ? 'visibility' : 'visibility_off'"
          @click:append="showConfirmPassword = !showConfirmPassword"
        ></v-text-field>
        <v-btn color="primary" @click.prevent="updatePassword">Update Password</v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { AuthenticationService } from "./../services/AuthenticationService";

const authenticationService = new AuthenticationService();

export default {
  data() {
    return {
      showOldPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      profile: {},
    };
  },
  methods: {
    async updatePassword() {
      let v = await this.$validator.validateAll();
      
      if (v) {
        this.$store.state.loader = true
        let userInSession = this.$store.state.userProfile

        this.profile.email = userInSession.email
        this.profile._id = userInSession._id

        authenticationService.updatePassword(this.profile).then(res => {
          this.$showSuccessMessage(res)
          this.profile = {};
        })
      }
    }
  }
};
</script>